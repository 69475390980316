import * as React from 'react';
import { Helmet } from 'react-helmet';
import Logo from '../components/Logo';

import { getAuth } from 'firebase/auth';
import {
	addDoc,
	collection,
	getFirestore,
	onSnapshot,
} from 'firebase/firestore';
import { MainButton, SecondaryButton } from '../components/Buttons';
import Login from '../components/Login';
const auth = getAuth();

const AdFreePage = () => {
	const [user, setUser] = React.useState(auth.currentUser);
	const [loading, setLoading] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState('');

	React.useEffect(() => {
		const unregisterAuthObserver = auth.onAuthStateChanged((currentUser) => {
			setUser(currentUser);
		});
		return () => unregisterAuthObserver();
	}, []);

	return (
		<>
			<Helmet>
				<title>Google Feud - Ad Free</title>
			</Helmet>
			<Logo />
			<ul
				className="altList"
				id="about"
				style={{
					display: 'inline-block',
					maxWidth: '600px',
					padding: '0 var(--single)',
				}}>
				<h1>Go Ad Free!</h1>
				<li key="l0" style={{ textAlign: 'center' }}>
					<i>Google Feud</i> is better without ads. Plus you support the
					developer.
				</li>
				{user ? (
					<>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<MainButton
								bigText
								disabled={loading}
								onClick={async () => {
									if (loading) {
										return;
									}
									setLoading(true);
									const docRef = await addDoc(
										collection(
											getFirestore(),
											`UserData/${user.uid}/checkout_sessions`
										),
										{
											cancel_url: window.location.origin,
											mode: 'subscription',
											price: 'price_1OmRZ7I8kpcbQsIDzrqgkpvC',
											success_url: window.location.origin,
										}
									);
									setLoading(false);
									onSnapshot(docRef, (snap) => {
										const { error, url } = snap.data() as {
											error?: { message?: string };
											url?: string;
										};
										if (error) {
											// Show an error to your customer and
											// inspect your Cloud Function logs in the Firebase console.
											setErrorMessage(`An error occured: ${error.message}`);
										}
										if (url) {
											// We have a Stripe Checkout URL, let's redirect.
											window.location.assign(url);
										}
									});
								}}
								text="$0.99 / month"
							/>
							<SecondaryButton
								bigText
								disabled={loading}
								onClick={async () => {
									if (loading) {
										return;
									}
									setLoading(true);
									const docRef = await addDoc(
										collection(
											getFirestore(),
											`UserData/${user.uid}/checkout_sessions`
										),
										{
											cancel_url: window.location.origin,
											mode: 'payment',
											price: 'price_1OmRZhI8kpcbQsIDqh29KMEZ',
											success_url: window.location.origin,
										}
									);
									setLoading(false);
									onSnapshot(docRef, (snap) => {
										const { error, url } = snap.data() as {
											error?: { message?: string };
											url?: string;
										};
										if (error) {
											// Show an error to your customer and
											// inspect your Cloud Function logs in the Firebase console.
											setErrorMessage(`An error occured: ${error.message}`);
										}
										if (url) {
											// We have a Stripe Checkout URL, let's redirect.
											window.location.assign(url);
										}
									});
								}}
								text="$9.99 / unlimited"
							/>
						</div>
						<div
							style={{
								color: loading ? undefined : 'red',
								textAlign: 'center',
							}}>
							{loading && 'Loading checkout...'}
							{errorMessage}
						</div>
					</>
				) : (
					<Login />
				)}
			</ul>
		</>
	);
};

export default AdFreePage;
